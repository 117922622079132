<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>Платіж #{{ getSelectedPaymentWithDictionaries.id }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">Картка платежу</div>

        <template v-slot:extension>
          <v-tabs v-model="currentTab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="(item, index) in tabs" :key="index">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(item, index) in tabs" :key="index">
          <v-card flat color="blue-grey lighten-5">
            <component
              v-if="isPaymentExist"
              :is="item.component"
              :payment="getSelectedPaymentWithDictionaries"
              :currentTab="currentTab"
            ></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import BackButton from '@/components/common/BackButton'

export default {
  name: 'PaymentCard',

  components: {
    BackButton,
    Info: () => import('@/components/finance/payment/Info'),
  },

  data() {
    return {
      currentTab: null,
      tabs: [
        {
          title: 'Загальна інфо',
          component: 'Info',
        },
      ],
    }
  },

  computed: {
    ...mapState('payments', ['payment']),
    ...mapState('dictionaries', ['paymentTypesDictionary', 'paymentStatusesDictionary', 'machinesTypesDictionary']),
    ...mapGetters('payments', ['getSelectedPaymentWithDictionaries']),

    currentCompanyId() {
      return this.$route.params.id
    },
    currentPaymentId() {
      return this.$route.params.paymentId
    },
    isPaymentExist() {
      return Object.entries(this.payment).length
    },
  },

  created() {
    this.loadCurrentPayment()
  },

  methods: {
    ...mapActions('payments', ['loadSelectedPayment']),
    ...mapActions('logs', ['displayWarningAlert']),

    async loadCurrentPayment() {
      if (this.currentCompanyId !== 'undefined') {
        const payload = {
          companyId: this.currentCompanyId,
          paymentId: this.currentPaymentId,
        }
        await this.loadSelectedPayment(payload)
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
  },
}
</script>

<style scoped></style>
